import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

const AProposPage = () => (
  <Layout>
    <Seo title="À propos" description="Anne Marie Sow. Doctorante en Sciences de l'Éducation" />
    <div className="relative py-16 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center">
        <StaticImage className="h-48 w-48 rounded-full" src="../images/maman.jpg" alt="" />
      </div>
      <div className="mt-6 text-lg max-w-prose mx-auto">
        <h1 className="text-center text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-900">
          À propos de Anne Marie Sow
        </h1>
        <p className="mt-8 text-xl text-gray-500 leading-8">
          Anne Marie Sow est une sénégalaise, née à Saint-Louis. Elle est chercheuse et doctorante en sciences de
          l'éducation à l’Université de Bordeaux où elle fait une thèse en Éducation Inclusive intitulée <a
          href="https://www.theses.fr/s346667">« Scolarisation et Qualité de vie des élèves atteints d'une maladie invalidante au Sénégal en milieu scolaire, familial et hospitalier. Le cas de la drépanocytose.»</a>
        </p>
      </div>
      <div className="mt-6 prose prose-primary prose-lg text-gray-500 mx-auto">
        <p>
          Elle est titulaire d'un Master à l’Institut de la Chaire Unesco en Sciences de l'Education de la Faculté des
          Sciences et Technologies de l'Education et de la Formation de l'Université Cheikh Anta Diop de Dakar (ISE
          CUSE-FASTEF-UCAD), Mention Très bien. Elle a fait un mémoire sur « l'implication des directeurs d'école
          élémentaire dans l'inclusion scolaire des enfants en situation de handicap au Sénégal ». Elle est titulaire
          d'un CAEMTP en Économie Familiale et Sociale à l’Ecole normale supérieure d'Enseignement technique et
          professionnel (ENSETP).
        </p>
        <p>
          Actuellement, Anne Marie est formatrice au Système d’Information et de Management du Ministère de l’Education
          (SIMEN). Elle a été Responsable Régionale Formation et Produits de l’ONG américaine Freedom from Hunger en
          Afrique de l'Ouest. Elle a travaillé à l'Université Gaston Berger en tant que Chef de Division Suivi et
          Projets. Elle a été aussi Chef de Division Qualité à la Direction de l'Education Préscolaire du Sénégal
          (DEPS). Auparavant, elle était Conseillère Technique en Santé/Nutrition du Ministre chargé de la Petite
          Enfance et pendant de nombreuses années, elle a été Professeur d’Economie Familiale et Sociale. Elle a été
          Conseillère Municipale, membre de la Délégation spéciale de la Commune d’Arrondissement de Dieuppeul Derklé.
        </p>
        <p>
          Elle a conçu et géré de nombreux projets de développement avec Amnesty, OSIWA, Ambassade des États-Unis,
          USAID, CNLS... Elle a plus de 36 ans d'expérience dans la mise en œuvre et l'évaluation de projets de
          développement notamment dans les domaines des droits humains, de l'enfance, de l'éducation inclusive, de la
          santé, de la nutrition, du genre, de la vie communautaire… Elle a aussi une grande expérience dans
          l’encadrement des Personnes Vivant avec le VIH, des réfugiés du Rwanda, des Handicapés et autres personnes
          vulnérables.
        </p>
        <p>
          Mme Sow a fondé et préside l'Association des Femmes et Jeunes de la Diaspora pour le Développement de
          l’Afrique (AFDEAA), une organisation de plus de 2000 membres. Elle a créé la Maison d’Encadrement et de
          Réinsertion des Enfants de la Rue (<a href="https://mererue.com">MERERUE</a>) et encadré plus de 1300
          orphelins, handicapés ou enfants vulnérables. Elle est également Secrétaire aux Finances de la Coalition
          sénégalaise des défenseurs des droits humains (COSEDDH), Vice-Présidente de l’Association Pour la défense des
          usagers de l’Eau, de l'Électricité, des Télécommunications et services (ADEETélS). Elle a été également membre
          du Conseil d’Administration et de la commission d’Audit de Nonviolent Peaceforce (NP), une organisation de
          maintien de la paix basée à Washington et à Bruxelles.
        </p>
        <p>
          Mme Sow est l'auteur de plusieurs ouvrages dont « Droits humains et micro-activités », « La cuisine
          sénégalaise, livret de recettes locales », « Peacekeeper en action, manuel du Peacekeeper pour l'éducation à
          la paix en milieu communautaire »... Elle a aussi publié un mémoire scientifique « Impact des conflits armés
          chez les femmes et les enfants en Afrique » et un mémoire de fin d’études sur « Le diabète sucré et le
          comportement alimentaire des Sénégalais » et plusieurs articles.
        </p>
        <p>
          Anne Marie est Alumni des Etats Unis de l’International Visitor Leadership Program (IVLP 2005). En 2012, elle
          est Winner du l’Alumni Engagement Innovation Found (AEIF 2012) avec son projet « Femmes et jeunes d’Afrique,
          unis pour la paix, la démocratie et la citoyenneté ». Avec son équipe, elle a créé un réseau pour la Paix en
          Afrique notamment au Sénégal, Mali, Niger, Guinée, Cameroun.
        </p>
      </div>
    </div>
  </Layout>
);

export default AProposPage;
